<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center">
                
                <div style="width:160px;margin-right:10px;">
                    <el-input type="text" v-model="query.name" placeholder="学科名称"></el-input>  
                </div>
                <el-button type="primary" @click="init">查询</el-button>
                <el-button type="primary" @click="$router.push({name:'subject_add'})">+ 添加</el-button>
            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="name" label="学科名称" align="center"></el-table-column>
                    <el-table-column prop="manager.name" label="管理员" align="center"></el-table-column>
                    <el-table-column prop="" label="管理员联系方式" align="center">
                        <template slot-scope="scope" v-if="scope.row.manager">
                            <span>{{ scope.row.manager.phone }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="管理员账号" align="center">
                        <template slot-scope="scope" v-if="scope.row.manager">
                            <span>{{ scope.row.manager.username }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作" align="right" width="180">
                        <template slot-scope="scope">
                            <el-button v-if="!scope.row.pid" type="text" size="mini" @click="$router.push({name:'subject_add',query:{id:scope.row.id}})">添加二级学科</el-button>
                            <el-button @click="$router.push({name:'subject_edit',query:{id:scope.row.id}})" type="text" size="small">编辑</el-button>
                            <el-popconfirm
                                style="margin-left: 10px;"
                                title="是否确定删除？"
                                @confirm="handleDel(scope.row)"
                            >
                                <el-button type="text" slot="reference" size="small">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <!-- <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :total="total"
                        :page-sizes="pageSizes"
                        :page-size="query.size"
                        @size-change="onSize"
                        @current-change="onCurrPage"
                        @prev-click="onCurrPage"
                        @next-click="onCurrPage"
                    >
                    </el-pagination>
                </div> -->
            </div>
            
        </div>
    </div>
</template>

<script>
import {tree,del} from '@/request/api/subject.js'
export default {
    data(){
        return {
            tableData: [],
            query:{name:'',page:1,size:10},
            pageSizes:[10,30,50,100],
            total:0 , //列表总数
            loading:false,
            isEdit:false ,//编辑状态
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='subject') {
                this.init()
            }
        }
    },

    created(){
        this.init()
    },

    methods:{
        init(){
            tree(this.query).then(res=>{
                this.tableData = res.data
            })
        },

        //删除
        handleDel(data){
            del({ids:[data.id]}).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.init()
                this.$notify({title: '成功',message: res.msg,type: 'success'});
            })
        },

        //每页条数
        onSize(val){
            this.query.size=val
            this.init()
        },
        onCurrPage(val){
            this.query.page = val
            this.init()
        }
    }
}
</script>


<style scoped lang="less">
.card {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
}

</style>

